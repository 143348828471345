@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}
.out-top {
    animation: rotate 20s linear infinite;
    transform-origin: 13px 25px;
}
.in-top {
    animation: rotate 10s linear infinite;
    transform-origin: 13px 25px;
}
.out-bottom {
    animation: rotate 25s linear infinite;
    transform-origin: 84px 93px;
}
.in-bottom {
    animation: rotate 15s linear infinite;
    transform-origin: 84px 93px;
}