.tail:before {
  content: "";
  position: absolute;
  z-index: -1;
  bottom: 0;
  left: -7px;
  height: 20px;
  width: 20px;
  background: #F9FAFB;
  border-bottom-right-radius: 15px;
}
.tail:after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: -10px;
  width: 10px;
  height: 20px;
  background: white;
  border-bottom-right-radius: 10px;
}